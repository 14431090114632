<template>
  <main class="confirmation" >
    <img
      src="@/assets/background.jpg"
      alt=""
      class="img-cover background-image"
    />
    <HorizontalScroll class="center">
      <div class="card">
        <Title v-if="error == 0" titleStyle="title" text="Oops" />
        <Title
          v-else
          titleStyle="title"
          :text="translateString('acknowledgment')"
        />
        <div>
          <img class="car-image" :src="carImage" alt="car image"/>
        </div>
        <div>
          <p v-if="error == 0" class="sm-text">
            {{ translateString("formError") }}
          </p>
          <p v-else class="sm-text">
            {{ translateString("getEmailConfirmation") }}
          </p>
        </div>
        <Button
          :label="translateString('goTo') + ' ' + companyName"
          btnClass="small "
          @action="next(link)"
        />
      </div>
      <div class="card" v-if="!hasCG">
        <Title titleStyle="title" :text="translateString('caetanoNotCheck')" />
        <div>
          <img src="@/assets/caetano-go-logo.svg" alt="caetano logo" />
        </div>
        <div>
          <p class="sm-text">{{ translateString("caetanoGoPerks") }}</p>
          <p class="sm-text">
            - {{ translateString("caetanoGoPerks1") }}; <br />
            - {{ translateString("caetanoGoPerks2") }}; <br />
            - {{ translateString("caetanoGoPerks3") }}.
          </p>
        </div>
        <Button
          :label="translateString('knowMore')"
          btnClass="small "
          @action="next(urlCaetanoGo)"
        />
      </div>
    </HorizontalScroll>
  </main>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import HorizontalScroll from "@/components/HorizontalScroll/HorizontalScroll.vue";
import Title from "@/components/Title/Title.vue";

import { translateString } from "@/functions/functions.js";

export default {
  name: "Confirmation",
  components: {
    Button,
    HorizontalScroll,
    Title,
  },
  data() {
    return {
      urlCaetanoGo: this.$store.state.urlCaetanoGo,
      finalError: this.$store.state.finalError,
      companyName: this.$store.state.companyName,
      link: this.$store.state.linkWebsite,
      hasCG: this.$store.state.hasCG,
      carImage: this.$store.state.carImage
        ? this.$store.state.carImage
        : require("@/assets/car_placeholder.png"),
      error: this.$route.params.error,
    };
  },
  methods: {
    translateString,
    next(link) {
      window.open(link, "_blank").focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  align-items: center;
  background-color: $textColor;
  display: flex;
  justify-content: center;
  height: auto !important;
  min-height: 100vh;
  padding: 0 0 2rem;
}

.car-image {
  max-width: 300px;
  mix-blend-mode: multiply;
  width: 80%;
}

.card {
  background-color: $whiteColor;
  border-radius: 5px;
  padding: 3rem 2rem;
  max-width: 500px;
  width: 80%;
  z-index: 99;
}

.background-image {
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  z-index: 1;
  height: 100%;
}
</style>
